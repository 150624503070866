import {
  AlertReserveSellCellDefinition,
  CellType,
  IconCellDefinition,
  PhotoCellDefinition,
} from '@/components/dataTables/cell'

export const IconHeader: IconCellDefinition = {
  align: 'center',
  width: 40,
  filterable: false,
  type: CellType.icon,
}

export const PhotoHeader: PhotoCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  type: CellType.photo,
}

export const AlertReserveSellCellHeader: AlertReserveSellCellDefinition = {
  type: CellType.alertReserveSell,
  align: 'center',
  width: 60,
  sortable: false,
  filterable: false,
}
