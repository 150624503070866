import { ResourceHeaders } from '..'
import {
  AlertReserveSellCellHeader,
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  IdHeader,
  PersonHeader,
  PriceHeader,
  VehicleHeader,
  WhatsappHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  {
    ...AlertReserveSellCellHeader, text: 'Alerta', value: '@',
  },
  { ...BusinessHeader, text: 'Negocio', value: 'deal.lead.pipeline' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: 'deal.lead.client',
    options: {
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'deal.channel' },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'deal',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, id: item.stock.id }),
    },
  }, VehicleHeader),
  { ...DateHeader, text: 'Fecha de venta', value: 'created' },
  { ...AppraisalStatusHeader, text: 'Estado de venta', value: '@' },
  {
    ...WhatsappHeader,
    text: 'Validador',
    value: 'validator',
    options: {
      action: {
        color: 'blue',
        always: true,
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'Validator'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...PriceHeader, text: 'Descuento otorgado', value: 'discountCalculated', width: 180 },
]

export default function (): ResourceHeaders {
  return headers
}
